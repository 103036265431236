import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'react-bootstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

//components
import Layout from '../components/layout'
import RenderMultiline from '../components/RenderMultiline'
import SEO from '../components/seo'

const PageTemplate = ({data}) => {
    const pageData = data.contentfulPage;

    return(
        <Layout>
            <SEO title={pageData.title} description={(pageData.introduction ? pageData.introduction.introduction : '')} />
            <section className="section">
                <Container>
                    <div className="has-padding-large has-bg-white">
                        <h1 className="heading">{pageData.title}</h1>
                        <div className="section-intro wysiwyg">
                            <RenderMultiline string={pageData.introduction.introduction} />
                        </div>
                        <div className="section-body wysiwyg">
                            {documentToReactComponents(pageData.content.json)}
                        </div>
                    </div>
                </Container>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        contentfulPage( slug: { eq: $slug }) {
            title
            introduction {
                introduction
            }
            content {
                json
            }
        }
    }
`
export default PageTemplate
